<template>
  <div class="align">
    <b-card>
      <!-- Media -->
      <b-media class="mb-1">
        <template #aside>
          <b-avatar
            ref="previewEl"
            :src="userData.avatar"
            :text="avatarText(userData.fullName)"
            :variant="`light-${resolveUserRoleVariant(userData.role_name)}`"
            size="90px"
            rounded
          />
        </template>
        <h4 class="mb-1">
          {{
            userData.first_name + " " + userData.last_name ||
            userData.display_name
          }}
        </h4>
      </b-media>

      <!-- User Info: Input Fields -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <b-row class="mt-1">
            <!-- Field: First Name -->
            <b-col cols="12" md="4">
              <validation-provider
                #default="validationContext"
                name="first name"
                rules="required"
              >
                <b-form-group label="First Name" label-for="first-name">
                  <b-form-input
                    id="first-name"
                    v-model="userData.first_name"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}</b-form-invalid-feedback
                  >
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Last Name -->
            <b-col cols="12" md="4">
              <validation-provider
                #default="validationContext"
                name="last name"
                rules="required"
              >
                <b-form-group label="Last Name" label-for="last-name">
                  <b-form-input
                    id="last-name"
                    v-model="userData.last_name"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}</b-form-invalid-feedback
                  >
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Email -->
            <b-col cols="12" md="4" v-if="user.role_name == 'GLOBAL_ADMIN'">
              <b-row>
                <b-col class="pr-0">
                  <validation-provider
                    #default="validationContext"
                    name="email"
                    rules="required"
                  >
                    <b-form-group label="Email" label-for="email">
                      <b-form-input
                        id="email"
                        v-model="userData.emailFirst"
                        :state="
                          getValidationState(validationContext) && domainName
                        "
                      />
                      <b-form-invalid-feedback>
                        {{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback
                      >
                      <b-form-invalid-feedback :state="domainName">
                        This email is part of a reserved domain. Please enter a
                        different email address.
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col class="mt-2 pl-0">
                  <validation-provider
                    #default="validationContext"
                    name="domain name"
                    rules="required"
                  >
                    <b-input-group prepend="@">
                      <b-form-select
                        v-model="userData.emailLast"
                        :options="domainNames"
                      ></b-form-select>
                    </b-input-group>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col></b-row
              >
            </b-col>

            <b-col cols="12" md="4" v-if="user.role_name !== 'GLOBAL_ADMIN'">
              <b-form-group label="Email" label-for="email">
                <b-form-input id="email" v-model="userData.email" readonly />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
              <validation-provider
                #default="validationContext"
                name="display name"
                rules="required"
              >
                <b-form-group label="Display Name" label-for="display-name">
                  <b-form-input
                    id="display-name"
                    v-model="userData.display_name"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}</b-form-invalid-feedback
                  >
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="4"
              v-if="
                user.role_name == 'GLOBAL_ADMIN' ||
                user.role_name == 'ACCOUNT_MANAGER'
              "
            >
              <validation-provider
                #default="validationContext"
                name="role"
                rules="required"
              >
                <b-form-group label="Role" label-for="role">
                  <v-select
                    id="role"
                    v-model="userData.role_name"
                    :options="role"
                    :clearable="false"
                    label="role_name"
                    class="per-page-selector d-inline-block mx-50"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}</b-form-invalid-feedback
                  >
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            type="submit"
            :disabled="
              userData.first_name == '' ||
              userData.last_name == '' ||
              userData.emailFirst == '' ||
              userData.display_name == ''
            "
          >
            <div class="align-items-center">
              <b-spinner
                small
                label="Text Centered"
                v-if="isLoading"
              ></b-spinner>
              <span class="button-title">Save Changes</span>
            </div>
          </b-button>
        </b-form>
      </validation-observer>
    </b-card>

    <b-card>
      <div class="d-flex">
        <feather-icon icon="UserIcon" size="19" />
        <h4 class="mb-0 ml-50">Company Details</h4>
      </div>
      <b-form class="mt-1">
        <b-row>
          <!-- Field: Birth Date -->
          <b-col cols="12" md="4">
            <b-form-group label="Company Name" label-for="company-name">
              <b-form-input
                id="company-name"
                v-model="userData.company_name"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label="Company Email" label-for="company-email">
              <b-form-input
                id="company-email"
                v-model="userData.company_email"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4" v-if="userData.company_website">
            <b-form-group label="Company Website" label-for="company-website">
              <b-form-input
                id="company-website"
                v-model="userData.company_website"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group
              label-for="company_address_lines1"
              label="Company Address"
            >
              <b-form-input
                id="company_address_lines1"
                v-model="userData.company_address_lines1"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4" v-if="userData.company_address_lines2">
            <b-form-group
              label-for="company_address_lines2"
              label="Company Address1"
            >
              <b-form-input
                id="company_address_lines2"
                v-model="userData.company_address_lines2"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4" v-if="userData.company_address_lines3">
            <b-form-group
              label-for="company_address_lines3"
              label="Company Address2"
            >
              <b-form-input
                id="company_address_lines3"
                v-model="userData.company_address_lines3"
                readonly
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BSpinner,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import useUsersList from "../users-list/useUsersList";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import router from "@/router";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BSpinner,
    vSelect,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      email,
    };
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    role: {
      type: Array,
      required: true,
    },
  },
  computed: {
    organizationNames() {
      return store.getters["app-organization/organizationsList"];
    },
    domainName() {
      return this.userData.emailFirst.includes("@") ? false : true;
    },
  },
  setup(props, { emit }) {
    const toast = useToast();
    let isLoading = ref(false);
    let domain_names = ref("");
    let domainNames = ref([]);
    const user = JSON.parse(localStorage.getItem("userData"));

    const { resolveUserRoleVariant } = useUsersList();
    const domainData = {
      company_id: props.userData.company_id,
    };

    store
      .dispatch("app-user/fetchDomainNames", domainData)
      .then((response) => {
        domainNames.value = response.data.name;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching domain names",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });

    const onSubmit = () => {
      isLoading.value = true;
      if (user.role_name == "GLOBAL_ADMIN") {
        props.userData.email =
          props.userData.emailFirst + "@" + props.userData.emailLast;
      }

      props.role.filter(function (value) {
        if (value.role_name == props.userData.role_name.role_name) {
          props.userData.role_id = value.role_id;
          props.userData.role_name = value.role_name;
        }
      });

      store
        .dispatch("app-user/updateUser", props.userData)
        .then(() => {
          isLoading.value = false;
          emit("refetch-data");
          toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success `,
              icon: "CoffeeIcon",
              variant: "success",
              text: `User updated successfully`,
            },
          });
          router.push("/apps/users/list"); // redirect to the feed
        })
        .catch(() => {
          isLoading.value = false;
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: `User could not update`,
            },
          });
        });
    };

    const { refFormObserver, getValidationState } = formValidation();

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign
        props.userData.avatar = base64;
      }
    );

    return {
      isLoading,
      user,
      resolveUserRoleVariant,
      avatarText,
      domain_names,
      domainNames,
      onSubmit,
      getValidationState,
      refFormObserver,
      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.align {
  margin-top: 0 !important;
  padding: 0 !important;
}
</style>
