<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userData === undefined">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'apps-users-list' }">
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>
    <b-breadcrumb class="pl-0 pb-1">
      <b-breadcrumb-item :to="{ name: 'apps-users-list' }">
        Users
      </b-breadcrumb-item>
      <b-breadcrumb-item active> Edit User </b-breadcrumb-item>
    </b-breadcrumb>
    <user-edit-tab-account
      v-if="userData"
      :role="role"
      :user-data="userData"
      class="mt-2 pt-75"
    />
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BBreadcrumb,
  BBreadcrumbItem,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import userStoreModule from "../userStoreModule";
import UserEditTabAccount from "./UserEditTabAccount.vue";
import UserEditTabInformation from "./UserEditTabInformation.vue";
import organizationStoreModule from "../../organization/organizationStoreModule";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BBreadcrumb,
    BBreadcrumbItem,
    UserEditTabAccount,
    UserEditTabInformation,
  },
  setup() {
    const userData = ref(null);
    const user = JSON.parse(localStorage.getItem("userData"));
    const toast = useToast();
    let role = ref([]);

    const USER_APP_STORE_MODULE_NAME = "app-user";
    const ORGANIZATION_APP_STORE_MODULE_NAME = "app-organization";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);
    if (!store.hasModule(ORGANIZATION_APP_STORE_MODULE_NAME))
      store.registerModule(
        ORGANIZATION_APP_STORE_MODULE_NAME,
        organizationStoreModule
      );
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      if (store.hasModule(ORGANIZATION_APP_STORE_MODULE_NAME))
        store.unregisterModule(ORGANIZATION_APP_STORE_MODULE_NAME);
    });
    store.commit("appConfig/SPINNER_CONFIG", true);

    store
      .dispatch("app-user/fetchUser", { id: router.currentRoute.params.id })
      .then((response) => {
        store.commit("appConfig/SPINNER_CONFIG", false);

        userData.value = response.data;
        userData.value.emailFirst = userData.value.email.split("@")[0];
        userData.value.emailLast = userData.value.email.split("@")[1];

        const domainData = {
          company_id: response.data.company,
        };
        store
          .dispatch("app-user/fetchRole", domainData)
          .then((response) => {
            role.value = response.data.roles;
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching role",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      })
      .catch((error) => {
        store.commit("appConfig/SPINNER_CONFIG", false);

        if (error.response.status === 404) {
          userData.value = undefined;
        }
      });

    return {
      userData,
      role,
    };
  },
};
</script>
